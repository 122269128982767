import React from 'react';
import './Loader.css'

const Loader = ({ full }) => {
    return (
        <div style={full && {
            backgroundColor: 'var(--color-background-light)', width: '100%',
            height: '100%'
        }} id="preloader">
            <div id="loader"></div>
        </div>
    )
}
export default Loader;