import React, { useState } from 'react';

import LikeButton from './LikeButton';
import CommentButton from './CommentButton';
import CommentText from './CommentText';
import ShareButton from './ShareButton';

const PostOptions = () => {
    const [toggle, setToggle] = useState(false);
    const [comments, setComments] = useState([]);

    const handleToggle = () => {
        setToggle(!toggle);
    }

    const handleComment = (comment) => {
        setComments([...comments, comment])
    }

    return (
        <>
        <div className='post__options'>
            <LikeButton />
            <CommentButton toggle={toggle} handleToggle={handleToggle} />
            <ShareButton />
        </div>
        <CommentText toggle={toggle}
                handleToggle={handleToggle}
                comments={comments}
                handleComment={handleComment}
            />
        </>
    )
}
export default PostOptions;

