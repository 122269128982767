import React from 'react';
import './Sidebar.css'

//Components
import SidebarRow from './SidebarRow';

//Icons
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import WorkIcon from '@mui/icons-material/Work';
import GroupsIcon from '@mui/icons-material/Groups';
import { ExpandMoreOutlined } from '@mui/icons-material';

const Sidebar = () => {
    return (
        <div className='sidebar'>
            <div><SidebarRow src='https://bidinnovacion.org/economiacreativa/wp-content/uploads/2014/10/speaker-3.jpg'
             title='User Name'
             role='manager' 
             /></div>
            <div><SidebarRow title='Friends' Icon={PeopleIcon} /></div>
            <div><SidebarRow title='Mesenger' Icon={ChatIcon} /></div>
            <div><SidebarRow title='Jobs Market' Icon={WorkIcon} /></div>
            <div><SidebarRow title='Channels' Icon={GroupsIcon} /></div>
            <div><SidebarRow title='Feeds Filters' Icon={ExpandMoreOutlined} /></div>
        </div>
    )
}
export default Sidebar;