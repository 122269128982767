import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useGetPosts(pageNumber) {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [serverPosts, setServerPosts] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(()=>{
    setServerPosts([])
  }, [pageNumber])

  useEffect(() => {
    setLoading('true');
    setError(false);

    let cancel;
    axios({
      method: 'GET',
      url: `https://feed.teamedin.io/getSomeFeed?page=${pageNumber}`,
      //params: {q:query, page: pageNumber}
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(res => {
      setServerPosts(prevServerPosts => {
        return [...prevServerPosts, ...res.data];
      })
      setHasMore(res.data.length > 0);
      setLoading(false);
      console.log('DATA', res.data);
    }).catch(e => {
      if (axios.isCancel(e)) return;
      setError(true);
    })

    return () => {
      cancel();
    };
  }, [pageNumber]);
  return { loading, error, serverPosts, hasMore };
}
