import AxieLogo from '../images/axie-logo.png';

const checkSourceGame = (game) => {
    if (game === "Axie Infinity") {
        return AxieLogo;
    }
    // if (source === "Youtube") {
    //     return youtubeLogo;
    // }
}

export default checkSourceGame;
