import React from 'react';

import Feeds from '../components/feeds/Feeds';
import Widgets from '../components/Widgets';

const Home = () => {
    return (
        <div className='home'>
            {/*Feeds*/}
            <Feeds />
            {/*widgets*/}
            <Widgets />
        </div>
    )
}
export default Home;