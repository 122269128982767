import discordLogo from '../images/Discord-logo.svg';
import youtubeLogo from '../images/YouTube-logo.svg'

const checkSourceSocial = (source) => {
    if (source === "Discord") {
        return discordLogo;
    }
    if (source === "Youtube") {
        return youtubeLogo;
    }
}

export default checkSourceSocial;
