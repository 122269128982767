import React from 'react';
import './Widgets.css';

import { topRankingsJSON } from '../endpoints/endpoints';
import TopRankings from './TopRankings';

const Widgets = () => {
    return (
        <div className='widgets'>
            <TopRankings JSON={topRankingsJSON} />
        </div>
    )
}
export default Widgets;