import React from 'react';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';


const CommentButton = ({ toggle, handleToggle }) => {

    return (
        <div onClick={() => { handleToggle() }} className='post__option'>
            <ChatBubbleOutlineIcon />
            <p>Comment</p>
        </div>
    )
}
export default CommentButton;