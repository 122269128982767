import React, { useState } from 'react';
import Comment from './Comment';

const CommentText = ({ toggle, handleToggle, comments, handleComment }) => {
    const [comment, setComment] = useState('');


    const sendComments = () => {
        comment && handleComment(comment);
        console.log(comments, comment)
        setComment('');
        handleToggle();
    }

    console.log(toggle)
    return (
        <div className='CommentText'>
            {comments ? comments.map((comm, i) => (
                <div className='comment__text' key={i}>
                    <Comment
                    postId={324234}
                    profilePic={'https://wdrfree.com/public/blog/Cool-Profile-Picture-16.jpg'}
                    timestamp={null}
                    username={'User Name'}
                    message={comm}
                    HTML={null}
                    source={null}
                    originUrl={null}
                    game={null}
                    />
                    </div>
            )) : null}
            <div className='post__option__comment' style={toggle ? { height: '300px' } : { height: 0 }}>
                <input onChange={(e) => { setComment(e.target.value) }} />
                <button onClick={sendComments}>POST</button>
            </div>
        </div>
    )
}
export default CommentText;