import React from 'react';
import './Header.css';

import { Link } from 'react-router-dom';

//Icons
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import WorkIcon from '@mui/icons-material/Work';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ForumIcon from '@mui/icons-material/Forum';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, IconButton } from '@mui/material';
import Dropdown from './Dropdown';

const Header = () => {
    return (
        <div className='header'>
            <div className='header__left'>
                <img src='https://www.teamedin.io/images/logo-final.svg' alt='teamedin logo' />
            </div>
            <div className='header__input'>
                <SearchIcon />
                <input placeholder='Search for anything' type='text' />
            </div>
            {/* <Dropdown title="test" items={["pipi","pipi"]}/> */}
            <div className='header__center'>
                <Link to='/' className='header__option header__option--active' >
                    <HomeIcon fontSize='large' />
                </Link>
                <div  className='header__option'>
                    <SportsEsportsIcon fontSize='large' />
                </div>
                <a href='https://axie.teamedin.io/jobMarket' className='header__option'>
                    <WorkIcon fontSize='large' />
                </a>
                <Link to='/' className='header__option'>
                    <SupervisedUserCircleIcon fontSize='large' />
                </Link>
            </div>
            <div className='header__right'>
                <Link to='/profile' className='header__info'>
                    <Avatar src='https://bidinnovacion.org/economiacreativa/wp-content/uploads/2014/10/speaker-3.jpg' />
                    <h4>User Name</h4>
                </Link>
                <IconButton className='header__info'>
                    <ForumIcon className='header__right__icons' />
                </IconButton>
                <IconButton className='header__info'>
                    <NotificationsActiveIcon className='header__right__icons' />
                </IconButton>
                <IconButton className='header__info'>
                    <ExpandMoreIcon className='header__right__icons' />
                </IconButton>
            </div>
        </div>
    )
}
export default Header;