import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './StoryReel.css';

//Components
import Story from './Story';

import { topRankingsJSON } from '../endpoints/endpoints';
import Loader from './Loader';

const StoryReel = () => {

    const workers = topRankingsJSON.workers;
    const managers = topRankingsJSON.managers;

    const cardList = [...workers, ...managers];

    return cardList ? <OwlCarousel
        items={5}
        className="owl-theme"
        loop
        dots={false}
        lazyLoad={true}
        autoplay={true}
        autoplayHoverPause={true}
        nav
        margin={8} >
        {cardList.map((card, i) => (
            <Story
                key={i}
                image={card.image}
                avatar={card.avatar}
                userName={card.userName}
                role={card.role}
            />
        ))}
    </OwlCarousel> : <Loader />

}
export default StoryReel;