import React from 'react';

import { Avatar } from '@mui/material';

import checkSourceSocial from '../../utils/checkSourceSocial';
import checkSourceGame from '../../utils/checkSourceGame';

const PostTopContent = ({profilePic, username, timestamp, source, originUrl, game}) => {
    return (
        <div className='post__top'>
            <div className='post__top__left'>
                <Avatar className='post__avatar' src={profilePic} />
                <div className='post__topInfo'>
                    <h3>{username ? username : 'User'}</h3>
                    <p>on {timestamp ? new Date(timestamp).toLocaleString() : new Date().toLocaleString()}</p>
                </div>
            </div>
            <div className='post__top__rigth'>
                <div className='post__icons__container'>
                    {source ? <a href={originUrl ? originUrl : '/#'} target="_blank" rel="noopener noreferrer"><img className='post__source__image' src={checkSourceSocial(source)} alt='post source' /></a> : null}
                    {game ? <a href='https://axieinfinity.com/' target="_blank" rel="noopener noreferrer"><img className='post__game__image' src={checkSourceGame(game)} alt='post game' /></a> : null}
                </div>
                {/* <div className='post__top__right__text'>chanels</div> */}
            </div>
        </div>
    )
}
export default PostTopContent;