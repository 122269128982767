import React, { useState } from 'react';
import './MessageSender.css';

import { Avatar } from '@mui/material';
// import VideoCamIcon from '@mui/icons-material/Videocam';
// import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
// import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

const MessageSender = ({addPost}) => {

    const [inputSummary, setInputSummary] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const avatarLink = 'https://bidinnovacion.org/economiacreativa/wp-content/uploads/2014/10/speaker-3.jpg'

    const handleSubmit = (e) => {
        e.preventDefault();
        addPost(avatarLink, 'User Name', inputSummary, imageUrl);
        setImageUrl('');
        setInputSummary('');
    }
    return (
        <div className='messageSender'>
            <div className='messageSender__top'><Avatar src={avatarLink} />
                <form>
                    <input value={inputSummary} onChange={(e) => setInputSummary(e.target.value)} className='messageSender__input__summary' placeholder='Post summary' type='text' />
                    <input value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} className='messageSender__input__url' placeholder='Image URL (Optional)' type='text' />
                    <button onClick={handleSubmit} type='submit'>Post</button>
                </form>
            </div>
            {/* <div className='messageSender__bottom'>
                <div className='messageSender__option'>
                    <VideoCamIcon style={{ color: 'red' }} />
                    <h3>Live Video</h3>
                </div>
                <div className='messageSender__option'>
                    <PhotoLibraryIcon style={{ color: 'green' }} />
                    <h3>Photo/Video</h3>
                </div>
                <div className='messageSender__option'>
                    <InsertEmoticonIcon style={{ color: 'orange' }} />
                    <h3>Feeling/Activity</h3>
                </div>
            </div> */}
        </div>
    )
}
export default MessageSender;