import React from 'react';
import './Post.css';


import PostOptions from './PostOptions';
import PostTopContent from './PostTopContent';

const VideoPost = ({ profilePic, username, timestamp, message, mp4, video, image, HTML, source, originUrl, game, isMp4File }) => {
    return (
        <div className='post'>
            <PostTopContent profilePic={profilePic} username={username} timestamp={timestamp} source={source} originUrl={originUrl} game={game} />
            {HTML ?
                <div className='post__html' dangerouslySetInnerHTML={{ __html: HTML }} /> : null}
            <div ><div style={image ? { backgroundImage: `url(${image})` } : {backgroundImage:'none'}} className='post__image__container'></div>
                {video ?
                    <div className="post__video">
                        <iframe
                            width="100%"
                            height="480"
                            src={video}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div> : null}
                {isMp4File ?
                    <div className="post__video">
                        <video width="100%" height="480" controls>
                            <source src={mp4} type="video/mp4" />
                        </video>
                    </div> : null}
                {message ?
                    <div className='post__bottom'>
                        <p>{message}</p>
                    </div> : null}
            </div>
            <PostOptions />
        </div>
    )
}
export default VideoPost;