import React from 'react';
import './TopRankings.css';

//Icons
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const TopRankings = ({ JSON }) => {

    const workers = JSON.workers
    const managers = JSON.managers

    //Number of users to display
    const number = 3;

    function slideIn(row, index) {
        setTimeout(function () {
            row.classList.remove('slide-out');
        }, (index*100) + 1000);
    }

    return (
        <div className='top__rankings'>
            <div className='top__rankings__workers'>
                <table>
                    <thead>
                        <tr className='slide-out'>
                            <th className='top__rankings__workers__header'><EmojiEventsIcon fontSize='x-small' /><span>Top {number} workers</span></th>
                        </tr>
                        <tr className='slide-out'>
                            <th>User</th>
                            <th>Game</th>
                            <th>scores</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workers ? workers.map((worker, x) => {
                            return x < number ? worker.games.map((game, i) => (
                                <tr style={i >= 0 && i !== worker.games.length - 1 ?
                                    { boxShadow: 'none' } : {}}
                                    key={i}
                                    className='slide-out'
                                >
                                    <td style={i >= 0 && i !== worker.games.length - 1 ?
                                        { boxShadow: 'none' } : {}}
                                        className='top__rankings__name'
                                    >
                                        {i === 0 ?
                                            worker.userName : null}
                                    </td>
                                    <td >{game.name}</td>
                                    <td>{game.scores}</td>
                                </tr>
                            )
                            ) : null
                        }) : null
                        }
                    </tbody>
                </table>
            </div>
            <div className='top__rankings__managers'>
                <table>
                    <thead>
                        <tr className='slide-out'>
                            <th colSpan="3" className='top__rankings__managers__header'><EmojiEventsIcon fontSize='x-small' /><span>Top {number} managers</span></th>
                        </tr>
                        <tr className='slide-out'>
                            <th>User</th>
                            <th>Game</th>
                            <th>Payout</th>
                        </tr>
                    </thead>
                    <tbody>
                        {managers ? managers.map((manager, x) => {
                            return x < number ? manager.games.map((game, i) => (
                                <tr style={i >= 0 && i !== manager.games.length - 1 ?
                                    { boxShadow: 'none' } : {}}
                                    key={i}
                                    className='slide-out'
                                >
                                    <td style={i >= 0 && i !== manager.games.length - 1 ?
                                        { boxShadow: 'none' } : {}}
                                        className='top__rankings__name'
                                    >
                                        {i === 0 ?
                                            manager.userName : null}
                                    </td>
                                    <td >{game.name}</td>
                                    <td>{game.commisions} %</td>
                                </tr>
                            )
                            ) : null
                        }) : null
                        }
                    </tbody>
                </table>
            </div>
            <div className='hidden__div'>{setTimeout(() => {
                Array.from(document.querySelectorAll('tr')).forEach(
                    (row, index) => slideIn(row, index))
            }, 100)}</div>



        </div>
    )
}
export default TopRankings;