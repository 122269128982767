import React from 'react';
import './Post.css';

import PostOptions from './PostOptions';
import PostTopContent from './PostTopContent';

const TextPost = ({ profilePic, username, timestamp, message, HTML, source, originUrl, game }) => {
    return (
        <div className='post'>
           <PostTopContent profilePic={profilePic} username={username} timestamp={timestamp} source={source} originUrl={originUrl} game={game} />
            {HTML ? <div className='post__html' dangerouslySetInnerHTML={{ __html: HTML }} /> : null}
            <div className='post__image'>
                {message ? <div className='post__bottom'>
                    <p>{message}</p>
                </div> : null}
            </div>
            <PostOptions />
        </div>
    )
}
export default TextPost;