import React from 'react';
import './Post.css';


import PostOptions from './PostOptions';
import PostTopContent from './PostTopContent';

const ImagePost = ({ profilePic, image, username, timestamp, message, HTML, source, originUrl, game }) => {
    return (
        <div className='post'>
            <PostTopContent profilePic={profilePic} username={username} timestamp={timestamp} source={source} originUrl={originUrl} game={game} />
            {HTML ? <div className='post__html' dangerouslySetInnerHTML={{ __html: HTML }} /> : null}
            <div style={image ? { backgroundImage: `url(${image})` } : {backgroundImage:'none'}} className='post__image__container'></div>
            <div className='post__image'>
                {image ? <img src={image} alt="post thumb" /> : null}
            </div>
            {message ? <div className='post__bottom'>
                <p>{message}</p>
            </div> : null}

            <PostOptions />
        </div>
    )
}
export default ImagePost;