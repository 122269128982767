import React, { useState } from 'react';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const LikeButton = () => {

    const [liked, setLiked] = useState(false);

    const handleLike = () => {
        setLiked(!liked)
        console.log(liked)
    }
    return (
        
            <div onClick={() => { handleLike() }} className='post__option'>
                <ThumbUpIcon style={liked? { color: "var(--color-primary)" } : {}} />
                <p>{liked?'Liked':'Like'}</p>
            </div>

  
    )
}
export default LikeButton;