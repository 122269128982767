import React from 'react';
import './SidebarRow.css';

import { Avatar } from '@mui/material'

const SidebarRow = ({ src, Icon, title, role }) => {

    return (
        <div className='sidebarRow'>
            {src && <Avatar src={src} className='avatar'/>}
            {Icon && <Icon />}
            <h4>{title}</h4>
        </div>
    )
}
export default SidebarRow;