import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

//Components
import Header from './components/Header';
import Sidebar from './components/Sidebar';

//Pages
import Home from './pages/Home';
import Profile from './pages/Profile';


function App() {
  return (
    <BrowserRouter >
      <div className="app">
        {/* Header */}
        <Header />
        {/*App body */}
        <div className='app__body'>
          {/*Sidebar*/}
          <Sidebar />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/profile' element={<Profile />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
