export const topRankingsJSON = {
    workers: [
        {
            workerId: 1,
            image: 'https://latestauto20.com/wp-content/uploads/2021/10/f2a41d641c9fcca1b9f12b08f5ffe4b4.jpg',
            avatar: 'https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg',
            userName: 'Sophie Lee',
            role: 'worker',
            games: [{
                name: 'Axie',
                scores: 5323,
            },
            {
                name: 'Lol',
                scores: 8323,
            }],

        },
        {
            workerId: 2,
            image: 'https://www.xda-developers.com/files/2022/01/OPPO-Find-X5-Pro-Wallpaper_2.jpg',
            avatar: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
            userName: 'Alex Roonie',
            role: 'worker',
            games: [{
                name: 'Axie',
                scores: 53453,
            },
            {
                name: 'Lol',
                scores: 836423,
            }],

        },
        {
            workerId: 3,
            image: 'https://mobcup.b-cdn.net/images/wt/d445199233c1a1756c9bee57dc678593.jpg',
            avatar: 'https://static.dir.bg/uploads/images/2009/11/17/1259936/768x.jpg?_=1528414657',
            userName: 'Sashka Vaseva',
            role: 'worker',
            games: [{
                name: 'Axie',
                scores: 53423,
            },
            {
                name: 'Lol',
                scores: 83263,
            }],

        }
    ],
    managers: [
        {
            managerId: 1,
            image: 'https://i.pinimg.com/736x/8e/6c/06/8e6c064f57f94838263d7ba9ad80f353.jpg',
            avatar: 'https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg',
            userName: 'Sarah Coner',
            role: 'manager',
            games: [{
                name: 'Axie',
                commisions: 56,
            },
            {
                name: 'Lol',
                commisions: 24,
            }],

        },
        {
            managerId: 2,
            image: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/19784979/VRG_Wallpapers_S20_Cable_02.0.jpg',
            avatar: 'https://colfecarga.com/taxi/assets/pages/media/profile/profile_user.jpg',
            userName: 'Jhon Doe',
            role: 'manager',
            games: [{
                name: 'Axie',
                commisions: 53,
            },
            {
                name: 'Lol',
                commisions: 30,
            }],

        },
        {
            managerId: 3,
            image: 'https://images.pexels.com/photos/2486168/pexels-photo-2486168.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
            avatar: 'https://www.morganstanley.com/content/dam/msdotcom/people/tiles/isaiah-dwuma.jpg.img.380.medium.jpg/1594668408164.jpg',
            userName: 'David Jhonson',
            role: 'manager',
            games: [{
                name: 'Axie',
                commisions: 48,
            },
            {
                name: 'Lol',
                commisions: 67,
            }],

        }
    ],

}