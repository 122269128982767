import React from 'react';
import './Post.css';

import PostTopContent from './PostTopContent';

const Comment = ({ profilePic, username, timestamp, message, HTML, source, originUrl, game }) => {
    return (
        <div style={{width: '100%'}} className='post'>
           <PostTopContent profilePic={profilePic} username={username+ " commented"} timestamp={timestamp} source={source} originUrl={originUrl} game={game} />
            {HTML ? <div className='post__html' dangerouslySetInnerHTML={{ __html: HTML }} /> : null}
            <div className='post__image'>
                {message ? <div style={{width:'100%'}} className='post__bottom'>
                    <p style={{textAlign:'left'}}>{message}</p>
                </div> : null}
            </div>
        </div>
    )
}
export default Comment;