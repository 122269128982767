import React from 'react';
import { FacebookShareButton } from "react-share";



import NearMeIcon from '@mui/icons-material/NearMe';

const ShareButton = () => {
    return (

        <FacebookShareButton
            url={"https://www.teamedin.io/"}
            quote={"NFT GAMING"}
            hashtag={"#teamedin"}
            description={"NFT GAMING"}
            className='post__option'
        >
            <div className='post__option'>
            <NearMeIcon />
            <p>Share</p>
        </div>
        </FacebookShareButton >
       
    )
}
export default ShareButton;