import { Avatar } from '@mui/material';
import React from 'react';
import './Story.css'

const Story = ({ image, avatar, userName, role }) => {
    const cardShadowColor = role === 'manager' ?
        { boxShadow: '0 7px 13px -7px var(--color-primary)', backgroundImage: `url(${image})` } :
        { boxShadow: '0 7px 13px -7px var(--color-secondary)', backgroundImage: `url(${image})` }

    const avatarRingColor = role === 'manager' ?
        { border: '5px solid var(--color-primary)' } :
        { border: '5px solid var(--color-secondary)' }

    return (
        <div style={cardShadowColor} className='story'>
            <div className='story__overlay'>
            <Avatar style={avatarRingColor} className='story__avatar' src={avatar} />
            <p>{role}</p>
            <h4>{userName}</h4>
            </div>
        </div>
    )
}
export default Story;