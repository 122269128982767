import React from 'react';

import './Profile.css'

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Axies vs Juice',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        {
            label: 'Axies',
            data: [12, 19, 3, 5, 2, 3,5],
            backgroundColor: 'rgba(9,238,183, 0.5)',
        },
        {
            label: 'Juice',
            data: [10, 29, 8, 15, 20, 1,9],
            backgroundColor: 'rgba(134,117,240, 0.5)',
        },
    ],
};


const Profile = () => {
    return (
        <div className='profile'>
            <Bar className='profile__bar_chart'
                height={400}
                width={600}
                title='Chart'
                options={options} data={data}
            />
        </div>
    )
}
export default Profile;