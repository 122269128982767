import React, { useEffect, useState, useRef, useCallback } from 'react';
import './Feeds.css';

//Hooks
import useGetPosts from '../../hooks/useGetPosts';

//Components
import MessageSender from '../MessageSender';
import StoryReel from '../StoryReel';
import TextPost from './TextPost';
import ImagePost from './ImagePost';
import VideoPost from './VideoPost';
import Loader from '../Loader';

const Feed = () => {

    const [pageNumber, setPageNumber] = useState(1);
    const [loadingPage, setLoadingPage] = useState(true);
    const [posts, setPosts] = useState([]);

    const { loading, error, serverPosts, hasMore } = useGetPosts(pageNumber);

    const observer = useRef();
    const eachPostRef = useCallback(node => {
        console.log(node)
    });
    const lastPostRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1);
                console.log('VISIBLE');
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore]);

    useEffect(() => {
        setPosts(prevPosts => {
            return [...prevPosts, ...serverPosts];
        })
    }, [serverPosts])

    const addPost = (avatar, name, summuary, imageURL) => {
        const newPost = {
            "_id": "61f6a934d4b80f64e6e04e0a",
            "description": summuary,
            "avatar": avatar,
            "origin": name,
            "originUrl": "https://www.youtube.com/channel/UC0SesdSOSgLERIZSj_FC8wQ",
            "source": "Youtube",
            "thumbnail": imageURL,
        }
        setPosts([newPost, ...posts])
    }

    setTimeout(()=>{
        setLoadingPage(false)
    }, 1700)

    return (
        <>{loadingPage ? <Loader full={true} /> :
            <div className='feeds' id='feeds'>
                {/*StoryReel*/}
                <StoryReel />
                {/*MessageSender*/}
                <MessageSender
                    addPost={(avatar, name, summuary, imageURL) => addPost(avatar, name, summuary, imageURL)}
                />
                {/*Post*/}
                {posts.map((post, i) => {
                    let isLastPost = posts.length === i + 1

                    if ((post.description || post.displayHTML) && !post.thumbnail && !post.video) {
                        return (
                            <div ref={isLastPost ?
                                lastPostRef :
                                eachPostRef}
                                key={i + post._id}>
                                <TextPost
                                    postId={post._id}
                                    profilePic={post.avatar ? post.avatar : 'https://wdrfree.com/public/blog/Cool-Profile-Picture-16.jpg'}
                                    timestamp={post.timestamp}
                                    username={post.origin}
                                    message={post.description}
                                    HTML={post.displayHTML}
                                    source={post.source}
                                    originUrl={post.originUrl}
                                    game={post.game}
                                />
                            </div>
                        )
                    }
                    if (post.thumbnail && !post.video && !post.thumbnail.includes('.mp4')) {
                        return (
                            <div ref={isLastPost ?
                                lastPostRef :
                                eachPostRef}
                                key={i + post._id}>
                                <ImagePost
                                    postId={post._id}
                                    profilePic={'https://wdrfree.com/public/blog/Cool-Profile-Picture-16.jpg'}
                                    timestamp={post.timestamp}
                                    username={post.origin}
                                    message={post.description}
                                    image={post.thumbnail}
                                    HTML={post.displayHTML}
                                    source={post.source}
                                    originUrl={post.originUrl}
                                    game={post.game}
                                />
                            </div>
                        )
                    }
                    if (post.video || post.thumbnail.toString().includes('.mp4')) {
                        let isMp4File = post.thumbnail.toString().includes('.mp4')
                        return (
                            <div ref={isLastPost ?
                                lastPostRef :
                                eachPostRef}
                                key={i + post._id}>
                                <VideoPost
                                    postId={post._id}
                                    profilePic={'https://wdrfree.com/public/blog/Cool-Profile-Picture-16.jpg'}
                                    timestamp={post.timestamp}
                                    username={post.origin}
                                    message={post.description}
                                    video={post.video}
                                    image={post.thumbnail}
                                    mp4={post.thumbnail}
                                    HTML={post.displayHTML}
                                    source={post.source}
                                    originUrl={post.originUrl}
                                    game={post.game}
                                    isMp4File={isMp4File}
                                />
                            </div>
                        )
                    }
                    else return null
                })}
                {loading && <Loader />}
                {error && <div>{error}</div>}
            </div>}</>
    )
}
export default Feed;